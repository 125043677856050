var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { useMemo } from 'react';
var RGBA_PATTERN = /rgba?\(([0-9]+),([0-9]+),([0-9]+)(?:,(1|0|0?\.[0-9]*))?\)/;
var LUMINANCE_DARK_THRESHOLD = 0.35;
export var AA_THRESHOLD_CONTRAST = 4.5;
export var AAA_THRESHOLD_CONTRAST = 7.0;
export var AA_LARGE_SIZE_THRESHOLD_CONTRAST = 3.0;
export var AAA_LARGE_SIZE_THRESHOLD_CONTRAST = 4.5;
/**
 * Returns true if the given color has a lower luminance than threshold (default
 * is 0.35), false otherwise.
 *
 * Prefer {useContrast} or {useHasContrastOnLight} because it's will determine
 * if a color is dark enough to have contrast with the background color.
 *
 * @param color
 * @param threshold
 *
 * @see useContrast
 * @see useHasContrastOnLight
 *
 * @example
 *
 *   useIsColorDark('#333')
 */
export function useIsColorDark(color, threshold) {
    if (threshold === void 0) { threshold = LUMINANCE_DARK_THRESHOLD; }
    return useColorLuminance(color) < threshold;
}
/**
 * Calculates the contrast between two colors
 *
 * @param color the foreground color
 * @param background the background color
 *
 * @returns the contrast 1:<result> between 1 and 21
 */
export function useContrast(color, background) {
    return useMemo(function () { return contrast(color, background); }, [color, background]);
}
/**
 * Calculates the contrast between two colors
 *
 * @param color the foreground color
 * @param background the background color
 *
 * @returns the contrast 1:<result> between 1 and 21
 */
export function contrast(color, background) {
    var la = colorLuminance(color);
    var lb = colorLuminance(background);
    var _a = [la, lb].sort(), l2 = _a[0], l1 = _a[1];
    return (l1 + 0.05) / (l2 + 0.05);
}
/**
 * Returns true if the color has at least an AA contrast with the background, if
 * the background is white.
 *
 * @param color
 * @param background defaults to white (#fff)
 * @param threshold {AA_THRESHOLD_CONTRAST}
 *
 * @see AA_THRESHOLD_CONTRAST
 * @see AA_LARGE_SIZE_THRESHOLD_CONTRAST
 * @see AAA_THRESHOLD_CONTRAST
 * @see AAA_LARGE_SIZE_THRESHOLD_CONTRAST
 */
export function useHasContrastOnLight(color, background, threshold) {
    if (background === void 0) { background = '#ffffff'; }
    if (threshold === void 0) { threshold = AA_THRESHOLD_CONTRAST; }
    return useContrast(color, background) > threshold;
}
/**
 * Returns true if the color has at least an AA contrast with the background, if
 * the background is black.
 *
 * @param color
 * @param background defaults to black (#fff)
 * @param threshold {AA_THRESHOLD_CONTRAST}
 *
 * @see AA_THRESHOLD_CONTRAST
 * @see AA_LARGE_SIZE_THRESHOLD_CONTRAST
 * @see AAA_THRESHOLD_CONTRAST
 * @see AAA_LARGE_SIZE_THRESHOLD_CONTRAST
 */
export function useHasContrastOnDark(color, background, threshold) {
    if (background === void 0) { background = '#000000'; }
    if (threshold === void 0) { threshold = AA_THRESHOLD_CONTRAST; }
    return useContrast(color, background) > threshold;
}
/**
 * Gives the color luminance (as a hook)
 * @param color the color
 * @returns luminance
 */
export function useColorLuminance(color) {
    return useMemo(function () { return colorLuminance(color); }, [color]);
}
var CACHE = {};
/**
 * Calculates the color's luminance
 *
 * @param color #rgb, #rrggbb[FF], rgb(r,g,b), rgba(r,g,b,1) or [r, g, b]
 */
export function colorLuminance(color, cache) {
    if (cache === void 0) { cache = true; }
    if (typeof color === 'string') {
        if (cache && CACHE[color]) {
            return CACHE[color];
        }
        if (color[0] === '#') {
            var rgb = color.slice(1);
            if (rgb.length === 3) {
                return (CACHE[color] = colorLuminance([
                    hexToByte(rgb[0] + rgb[0]),
                    hexToByte(rgb[1] + rgb[1]),
                    hexToByte(rgb[2] + rgb[2]),
                ]));
            }
            if (rgb.length === 8) {
                var alpha = hexToByte(rgb.slice(6, 8));
                if (alpha !== 255) {
                    throw new NeedsAlphaBlending(color, alpha);
                }
            }
            if (rgb.length === 6 || rgb.length === 8) {
                return (CACHE[color] = colorLuminance([
                    hexToByte(rgb.slice(0, 2)),
                    hexToByte(rgb.slice(2, 4)),
                    hexToByte(rgb.slice(4, 6)),
                ]));
            }
            throw new UnsupportedFormat(color);
        }
        var matches = color.replace(/ /g, '').match(RGBA_PATTERN);
        if (matches) {
            if (matches[4] && matches[4] !== '1') {
                throw new NeedsAlphaBlending(color, Number(matches[4]));
            }
            return (CACHE[color] = colorLuminance([
                Number(matches[1]),
                Number(matches[2]),
                Number(matches[3]),
            ]));
        }
        throw new UnsupportedFormat(color);
    }
    assertRgbColor(color);
    return componentsToLuminance(color[0] / 255, color[1] / 255, color[2] / 255);
}
function hexToByte(hex) {
    return parseInt(hex, 16);
}
function componentsToLuminance(r, g, b) {
    var rl = r > 0.03928 ? Math.pow(((r + 0.055) / 1.055), 2.4) : r / 12.92;
    var gl = g > 0.03928 ? Math.pow(((g + 0.055) / 1.055), 2.4) : g / 12.92;
    var bl = b > 0.03928 ? Math.pow(((b + 0.055) / 1.055), 2.4) : b / 12.92;
    // https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
    // https://en.wikipedia.org/wiki/Relative_luminance
    return 0.2126 * rl + 0.7152 * gl + 0.0722 * bl;
}
function assertRgbColor(color) {
    if (color.length < 3 ||
        color.length > 4 ||
        color.some(function (d) { return d < 0 || d > 255; })) {
        throw new UnsupportedFormat("[" + color.join(', ') + "]");
    }
    if (color.length == 4 && color[3] !== 1) {
        throw new NeedsAlphaBlending("[" + color.join(', ') + "]", color[3]);
    }
}
var UnsupportedFormat = /** @class */ (function (_super) {
    __extends(UnsupportedFormat, _super);
    function UnsupportedFormat(color) {
        return _super.call(this, ("\nExpected color to be in a supported format, actual: " + color + ".\n\nSupported formats for string input are:\n\n- #rgb\n- #rrggbb\n- #rrggbbFF\n- rgb(r, g, b)      (0 <= rgb <= 255)\n- rgba(r, g, b, 1)  (0 <= rgb <= 255)\n\nSupported formats for array input are:\n\n - [r, g, b]         (0 <= rgb <= 255)\n - [r, g, b, 1]      (0 <= rgb <= 255)\n\nKeyword/system colors are not supported (nor consistent across environments).\n      ").trim()) || this;
    }
    return UnsupportedFormat;
}(Error));
export { UnsupportedFormat };
var NeedsAlphaBlending = /** @class */ (function (_super) {
    __extends(NeedsAlphaBlending, _super);
    function NeedsAlphaBlending(color, alpha) {
        return _super.call(this, ("\nExpected a fully opaque color, actual: " + color + ", with alpha: " + alpha + ".\n\nColors with that are (semi-)translucent need to be alpha-blended, which means\nthat you need to know the background color(s) in order to calculate the color\nthat will show on screen.\n\nThe package use-alpha-blended-color has both a React hook as well as a general\nutility function to premultiply colors.\n\n  import { useAlphaBlendedColor } from 'use-alpha-blended-color'\n\n  const prepared = useAlphaBlendedColor(" + color + ", '#<background-color>')\n  const luminance = useColorLuminance(prepared)\n      ").trim()) || this;
    }
    return NeedsAlphaBlending;
}(Error));
export { NeedsAlphaBlending };
