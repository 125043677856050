import { useRef, useEffect } from 'react';
/**
 * Returns a ref that contains a value that is true when the component is
 * mounted and false when it's no longer mounted.
 */
export function useIsMounted() {
    var isMountedRef = useRef(true);
    useEffect(function () {
        isMountedRef.current = true;
        return function () {
            isMountedRef.current = false;
        };
    }, [isMountedRef]);
    return isMountedRef;
}
